<template>
  <div
    v-show="isDialogShow"
    class="guide_dialog"
    @touchmove.prevent
  >
    <el-dialog
      :visible.sync="isDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :modal="false"
      :width="w"
    >
      <div class="content">
        <video id="preview" />
        <div
          class="btn_1"
          @click="noShowClick"
        >
          <span>關閉</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Qrcode',
  props: {
    isDialogShow: { type: Boolean, default: false },
    noShowClick: { type: Function, default: () => {} },
    noClick: { type: Function, default: () => {} },
    isShowClick: { type: Function, default: () => {} },
    warning: { type: Boolean, default: false },
    warning_: { type: Boolean, default: false },
    filter: { type: Boolean, default: false },
    filter_: { type: Boolean, default: false },
    updateFailed: { type: Boolean, default: false },
    // succeed: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    ForSuccessful: { type: Boolean, default: false },
    ForFailure: { type: Boolean, default: false },
    w: { type: String, default: '35rem' },
    modyType: String
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
#preview {
  border: solid #bfbfbf 1px;
}
.content {
  overflow: hidden;
}
.guide_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  webkit-overflow: hidden;
  background-color:rgba(0, 0, 0, .6);
  /deep/ .el-dialog__wrapper{
  //  webkit-z-index: 9996!important;
  //  overflow: hidden;
  //  webkit-overflow: hidden;
  }
  /deep/ .el-dialog {
    // z-index: 9999!important;
    width: 35rem;
    border-radius: 1.4rem!important;
    /deep/ .el-dialog__body {
      padding: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2.5rem;
      .imgs {
        display: flex;
        justify-content: center;
        width: 9rem;
        height: 8rem;
        img {
          height: 8rem;
        }
      }
      h1 {
        margin-top: 1.5rem;
        line-height: 3rem;
        font-size: 2.2rem;
        color: rgba(61, 61, 61, 1);
        font-weight: 700;
      }
      p{
        margin-top: .6rem;
      }
      p,.btn_2 {
        line-height: 1.7rem;
        font-size: 1.2rem;
        color: rgba(134, 134, 134, 1);
      }
      a {
        font-size: 1.2rem;
        line-height: 2rem;
        color: #c8161d;
      }
      .btn_1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14.5rem;
        height: 4.6rem;
        margin-top: 4rem;
        background: #c8161d;
        border-radius: .6rem;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }
      .btn_2 {
        margin-top: 1.2rem;
        cursor: pointer;
      }
    }
  }
}
</style>
